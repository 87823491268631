// VOOR AWARD
.hide{
  display: none;
}
// VOOR AWARD

#header-container{
  background-position: bottom center;
  background-size: initial;
  text-align: center;
  height: 210px;
  position: relative;

  @include breakpoint(sm){
    height: 350px;
  }

  .stain{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url(../../images/bg-header.png);
    background-position: bottom center;
    background-repeat: no-repeat;

    z-index: 1;
  }

  .container{
    padding: 50px 20px;
    z-index: 2;
    position: relative;

    @include breakpoint(sm){
      padding: 70px 30px;
    }
  }

  .content{
    @include grid-placement(startLine, endLine, startLine, endLine, 1, 1, 1, 5 );
    z-index: 2;
    text-align: center;
  }

  h2{
    @include font-size(18);
    text-align: center;
    margin: 0 auto;
    max-width: 220px;

    @include breakpoint(sm){
      @include font-size(24);
      max-width: none;
    }

    + a{
      margin-top: 5px;
      display: inline-block;
      padding: 4px;
      margin-left: -4px;

      &:hover{
        border-bottom: 3px solid rgba(0,0,0,0.3);
      }
    }
  }

  img{
    &.logo{
      max-width: 230px;
      margin: 0 auto;
      margin-bottom: 20px;

    @include breakpoint(sm){
        width: 370px;
        margin-bottom: 50px;
        max-width: 350px;
      }
    }
  }


}

#teaser-container{
  background-position: bottom left;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;

  @include breakpoint(sm){
    background-position: bottom center;
  }

  .paper-rip{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url(../../images/ripped-paper-top.png);
    background-position: top center;
    background-repeat: repeat-x;

    z-index: 4;
  }

  .container{
    padding: 80px 20px 20px;
    position: relative;

    @include breakpoint(sm){
      padding: 160px 30px 100px;
    }
    z-index: 5;
  }

  .content{
    @include grid-placement(startLine, endLine, startLine, leftSectionLine, 1, 1, 1, 1 );
    z-index: 3;

    a{
      font-family: "Chelsea Market", sans-serif;
      @include font-size(16);
      font-weight: normal;
      color: $green-color;

      padding: 4px;
      margin-left: -4px;

      img{
        height: 12px;
      }

      &:hover{
        border-bottom: 3px solid rgba(255,255,255,0.3);
      }

      @include breakpoint(sm){
        @include font-size(20);
        color: $white-color;

        img{
          height: 16px;
        }
      }
    }
  }

  .webm{
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    height: 100%;
    width: 100%;
    overflow: hidden;

    video{
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
    }

    @include breakpoint(sm){
      display: block;
    }
  }

  h3{
    @include font-size(18);
    text-align: left;
    margin-bottom: 0px;

    @include breakpoint(sm){
      color: $white-color;
      @include font-size(36);
      max-width: none;
    }
  }

  p{
    text-align: left;
    @include breakpoint(sm){
      color: $white-color;

      img{
        content: url(../../images/arrow-right.png);
      }
    }
  }
}

#meaning-container{
  background-position: bottom center;
  background-size: initial;
  text-align: left;

  @media (min-width: 767px) {
    .container{
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 1fr [leftSectionLine] 1fr [rightSectionLine] 1fr [endLine]',
        30px,
        'auto',
        '1fr 30px 1fr 30px 1fr',
        30px
      );
    }
  }

  @include breakpoint(sm){
    text-align: center;
  }

  .title-container{
    @include grid-placement(1, span 1, startLine, endLine, 1, 1, 1, 5 );

    h2{
      @include font-size(18);
      text-align: left;

      @include breakpoint(sm){
        @include font-size(36);
        text-align: center;
      }

      margin: 0px;
    }

    p{
      margin: 5px 0 0;
    }
  }

  .content{
      &:nth-child(2){
        @include grid-placement(auto, span 1, startLine, endLine, 2, 1, 1, 1 );
      }
      &:nth-child(3){
        @include grid-placement(auto, span 1, startLine, endLine, 3, 1, 1, 1 );
      }
      &:nth-child(4){
        @include grid-placement(auto, span 1, startLine, endLine, 4, 1, 1, 1 );
      }

    @media (min-width: 767px) {
      &:nth-child(2){
        @include grid-placement(2, span 1, auto, span 1, 2, 1, 1, 1 );
      }
      &:nth-child(3){
        @include grid-placement(2, span 1, auto, span 1, 2, 1, 3, 1 );
      }
      &:nth-child(4){
        @include grid-placement(2, span 1, auto, span 1, 2, 1, 5, 1 );
      }
    }

    z-index: 2;
    text-align: center;

    .img{
      height: 190px;
      margin-bottom: 10px;
      position: relative;

      @include breakpoint(sm){
        height: 224px;
        margin-bottom: 30px;
      }

      img{
        width: auto;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
    }

    h3{
      @include font-size(14);

      @include breakpoint(sm){
        @include font-size(24);
      }
    }
  }

  h2{
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 0px;
  }

  img{
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  @include breakpoint(sm){
    img{
      width: 370px;
    }
  }
}

#demo-container{
  background-position: bottom center;
  background-size: initial;
  text-align: center;
  display: none;

  @include breakpoint(sm){
    display: block;
  }

  .title-container{
    @include grid-placement(auto, span 1, startLine, endLine, 1, 1, 1, 5 );
  }

  .video-container{
    @include grid-placement(auto, span 1, startLine, endLine, 2, 1, 1, 5 );
    z-index: 2;
    text-align: center;
    height: 433px;
    position: relative;

    img{
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      &.overlay{
        display: none;
      }
    }

    video{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      width: auto;
      top: 17px;
      height: 396px;
    }

    .time-out-btn{
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 3;
      height: 182px;
      width: 182px;
      background-image: url(../../images/btn-timeout.png);
      border-radius: 91px;
      box-shadow: 0 8px 10px rgba(0,0,0,0.2);

      span{
        background-image: url(../../images/btn-timeout-hover.png);
        cursor: pointer;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: none;
      }

      &:hover{
        cursor: pointer;
        span{
          display: block;
        }
      }
    }
  }
}

#trailer-container{
  background-position: bottom center;
  background-size: initial;
  text-align: left;

  .container{
    padding: 0px 20px 60px;

    @include breakpoint(sm){
     padding: 100px 30px;
    }
  }

  .col-1-1{
    @include grid(
      '[startLine] auto [endLine]',
      '[startLine] 1fr [endLine]',
      30px,
      'auto',
      '1fr',
      30px
    );

    @include breakpoint(md){
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 5fr [margeLine] 1fr [leftSectionLine] 6fr [endLine]',
        30px,
        'auto',
        '1fr 30px 1fr',
        30px
      );
    }
  }

  h2{
    @include font-size(18);
    text-align: left;

    @include breakpoint(sm){
      @include font-size(24);
      text-align: left;
    }

    margin: 0px;
  }

  .content{
    @include grid-placement(auto, span 1, auto, span 1, 1, 1, 1, 1 );

    @include breakpoint(md){
      @include grid-placement(auto, span 1, startLine, margeLine, 1, 1, 1, 1 );
    }
  }

  .video-container{
    @include grid-placement(auto, span 1, auto, span 1, 2, 1, 1, 1 );

    @include breakpoint(md){
      @include grid-placement(auto, span 1, leftSectionLine, endLine, 1, 1, 3, 1 );
    }

    z-index: 2;

    .video-content{
      width: 100%;

      &:after{
        @include responsive-ratio(16,9);
        @include pseudo($pos: relative);
      }

      background-color: $white-color;
      border-radius: 10px;

      overflow: hidden;
      background-image: url(../../images/bg-trailer.jpg);
      background-size: cover;
      background-position: center center;
      position: relative;
      cursor: pointer;

    }
  }
}

#quote-container{
  display: none;

  @include breakpoint(sm){
    display: -ms-grid;
    display: grid;
  }

  .container{
    padding-bottom: 0;
    padding-top: 40px;
  }

  .content{
    @include grid-placement(auto, span 1, startLine, endLine, auto, span 1, 1, 5 );
    text-align: center;
    padding: 0 80px;
    text-align: center;

    h2{
      @include font-size(24);
    }

    blockquote{
      @include font-size(24);

      position: relative;

      &:before{
        position: absolute;
        left: -40px;
        top: -15px;
        height: 27px;
        width: 30px;
        background-image: url(../../images/icon-quotation-marks.png);
        background-position: center center;
        content: "";
        background-repeat: no-repeat;
      }

      &:after{
        position: absolute;
        right: -5px;
        bottom: -10px;
        height: 27px;
        width: 30px;
        background-image: url(../../images/icon-quotation-mark-end.png);
        background-position: center center;
        content: "";
        background-repeat: no-repeat;
      }
    }
  }
}

#design-container{
  .container{
    padding: 0px 20px 60px;

    @include breakpoint(sm){
       padding: 100px 30px;
    }
  }

  .col-1-1{
    @include grid(
      '[startLine] auto [endLine]',
      '[startLine] 1fr [endLine]',
      30px,
      'auto',
      '1fr',
      30px
    );

    @include breakpoint(md){
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 5fr [margeLine] 1fr [leftSectionLine] 6fr [endLine]',
        30px,
        'auto',
        '1fr 30px 1fr',
        30px
      );
    }
  }

  h2{
    @include font-size(18);
    text-align: left;

    @include breakpoint(sm){
      @include font-size(24);
      text-align: left;
    }

    margin: 0px;
  }

  .content{
    @include grid-placement(auto, span 1, auto, span 1, 1, 1, 1, 1 );

    @include breakpoint(sm){
      @include grid-placement(auto, span 1, startLine, margeLine, 1, 1, 1, 1 );
    }
  }

  .image-container{
    @include grid-placement(auto, span 1, auto, span 1, 2, 1, 1, 1 );

    text-align: center;

    img{
      max-width: 100%;
    }

    @include breakpoint(md){
      @include grid-placement(auto, span 1, leftSectionLine, endLine, 1, 1, 3, 1 );

      img{
        max-width: none;
      }
    }
    z-index: 2;
  }
}

#awards-container{
  text-align: center;

  background-image: url(../../images/bg-green-tiled.jpg);
  background-position: bottom center;
  background-size: 256px 256px;
  position: relative;

  .container{
    margin-top: -80px;
    z-index: 3;
    padding-bottom: 160px;
    position: relative;

    @include breakpoint(xs){
      padding-bottom: 80px;
    }

    @media (min-width: 767px) {
      text-align: center;

      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 1fr [leftSectionLine] 1fr [rightSectionLine] 1fr [endLine]',
        30px,
        'auto',
        '1fr 30px 1fr 30px 1fr',
        30px
      );

    }

  }

  .paper-rip{
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url(../../images/ripped-paper-top.png);
    background-position: top center;
    background-repeat: repeat-x;

    z-index: 2;
  }

  .paper-rip-bottom{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;

    background-image: url(../../images/ripped-paper-bottom.png);
    background-position: bottom center;
    background-repeat: repeat-x;

    z-index: 2;
  }

  .green-splat{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url(../../images/bg-green-splat.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    z-index: 1;
    display: none;

    @include breakpoint(sm){
      display: block;
    }
  }

  .content{

    &:nth-child(1){
      @include grid-placement(auto, span 1, auto, span 1, 1, 1, 1, 1 );
    }
    &:nth-child(2){
      @include grid-placement(auto, span 1, auto, span 1, 2, 1, 1, 1 );
    }
    &:nth-child(3){
      @include grid-placement(auto, span 1, auto, span 1, 3, 1, 1, 1 );
    }

    @media (min-width: 767px) {
      &:nth-child(1){
        @include grid-placement(1, span 1, auto, span 1, 1, 1, 1, 1 );
      }
      &:nth-child(2){
        @include grid-placement(1, span 1, auto, span 1, 1, 1, 3, 1 );
      }
      &:nth-child(3){
        @include grid-placement(1, span 1, auto, span 1, 1, 1, 5, 1 );
      }
    }

    margin-bottom: 30px;

    @include breakpoint(sm){
      margin-bottom: 0px;
    }

    h3{
      color: $white-color;
      @include font-size(24);
      margin-bottom: 0;
    }

    blockquote{
      color: $white-color;
      margin: 5px 0 0px;
      padding: 0;
      width: 100%;
      font-family: Helvetica, Arial, sans-serif;
      font-style: italic;
      @include font-size-and-line-height(16,24);

      &:after{
        content: "\"";
      }
      &:before{
        content: "\"";
      }

    }

    .image-container{
      margin-bottom: 30px;
      position: relative;

      @media (min-width: 767px) {
        height: 236px;

        img{
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &:last-of-type{
      img{
        top: 20px;
      }
    }
  }
}

#callout-container{
  .container{
    padding: 0;

    @include breakpoint(sm){
      padding: 30px 30px 0;
    }
  }

  .content{
    @include grid-placement(auto, span 1, startLine, margeLine, 1, 1, 1, 5 );
    text-align: center;

    padding: 0 20px;

    @include breakpoint(sm){
      padding: 0;
    }

    h2{
      @include font-size(18);

      @include breakpoint(sm){
        @include font-size(24);
      }

      position: relative;
    }
  }
}

#contact-container{
  .col-1-1{
    @include grid(
      '[startLine] auto [endLine]',
      '[startLine] 1fr [endLine]',
      30px,
      'auto',
      '1fr',
      30px
    );
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(xs){
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @include breakpoint(sm){
      padding-left: 30px;
      padding-right: 30px;

      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 5fr [margeLine] 2fr [leftSectionLine] 5fr [endLine]',
        30px,
        'auto',
        '5fr 30px 2fr 30px 5fr',
        30px
      );
    }
  }

  .content{
    h2{
      @include font-size(18);
      text-align: left;

      @include breakpoint(sm){
        @include font-size(24);
      }
    }

    .contact{
      margin-top: 30px;

      h2{
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 0px;

        + p{
          margin-top: 5px;
        }
      }
    }

    .mugshot{
      height: 96px;
      width: 96px;
      border-radius: 48px;
      float: left;
      margin-right: 20px;
      background-image: url(../../images/mugshot-paul.jpg);
      background-repeat: no-repeat;
      background-size: initial;
      background-position: center center;
    }

    &:nth-child(1){
      @include grid-placement(auto, span 1, startLine, margeLine, 1, 1, 1, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 1, 1, 1, 1);

        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &:nth-child(2){
      @include grid-placement(auto, span 1, leftSectionLine, endLine, 1, 1, 5, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 2, 1, 1, 1);
      }

      position: relative;

      .inner-content{
        padding: 80px 0 120px 30px;

        @include breakpoint(sm){
          position: absolute;
          top: -80px;
          left: -60px;
          bottom: -80px;
          padding: 80px 0 80px 60px;
        }

        background-image: url(../../images/bg-hatch.jpg);
        background-repeat: no-repeat;
        background-size: initial;
        background-position: center center;
      }
    }

    a{
      font-weight: normal;
      text-decoration: underline;
    }
  }
}

#sponsor-container{
    p{
        a{
            text-decoration: underline;
        }
    }

  .container{
    padding-bottom: 240px;
    padding-top: 60px;

    @include breakpoint(xs){
      padding-bottom: 80px;
    }
  }

  .content{
    // p{
    //   @include font-size-and-line-height(14,24);
    // }

    @include breakpoint(xs){
      padding-bottom: 30px;
    }

     &:nth-child(1){
      @include grid-placement(auto, span 1, startLine, leftSectionLine, 2, 1, 1, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 2, 1, 1, 1);
      }
    }

     &:nth-child(2){
      @include grid-placement(auto, span 1, leftSectionLine, middleSectionLine, 2, 1, 3, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 3, 1, 1, 1);
      }
    }

     &:nth-child(3){
      @include grid-placement(auto, span 1, middleSectionLine, rightSectionLine, 2, 1, 5, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 4, 1, 1, 1);
      }
    }

     &:nth-child(4){
      @include grid-placement(auto, span 1, rightSectionLine, endLine, 2, 1, 7, 1);

      @include breakpoint(xs){
        @include grid-placement(auto, span 1, auto, span 1, 5, 1, 1, 1);
      }
    }
  }

  .image-container{
    height: 154px;
    margin-bottom: 30px;
    position: relative;

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      max-width: 180px;
      max-height: 154px;
      height: auto;
    }
  }
}

#youtube-lightbox{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 99;
  display: none;

  .limiter{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    @include breakpoint(xs){
      width: 100%;
    }

    @include breakpoint(sm){
      width: 750px;
    }

    @include breakpoint(sm){
      width: 960px;
    }

    @include breakpoint(lg){
      width: 1170px;
    }
  }

  .close{
    position: absolute;
    top: -50px;
    right: 0px;

    color: $white-color;
    opacity: .5;
    font-family: 'Chelsea Market', cursive;
    font-size: 3.6rem;

    cursor: pointer;

    &:hover{
      opacity: 1;
    }
  }

  .player{
    position: relative;
    @include responsive-ratio(16,9);
    max-height: 100%;
    width: auto;
    height: auto;
    top: 0;
    left: 0;

    .border-radius{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 30px;
      overflow: hidden;
    }
  }

  #player{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

#story-container{
    p{
        a{
            text-decoration: underline;
        }
    }
    
  @include breakpoint(sm){
    @include grid(
      '[startLine] auto [endLine]',
      '[leftSectionLine] 1fr [contentLine] 680px [rightSectionLine] 1fr [endLine]',
      0px,
      'auto',
      '1fr 680px 1fr',
      0px
    );
  }

  .container{
    padding-top: 40px;
  }

  .content{
    @include grid-placement(auto, span 1, startLine, endLine, auto, span 1, 1, 5 );

    text-align: left;

    img{
      max-width: 100%;
      width: 100%;
    }

    img{
      + p{
        font-size: 1.2rem;
        line-height: 1.8rem;
        opacity: 0.4;
        padding-top: 0;
        margin-top: 0;

        @include breakpoint(md){
          font-size: 1.4rem;
          line-height: 2.1rem;
        }
      }
    }

    p{
      + h2{
        margin-top: 45px;
      }
    }
  }
}
