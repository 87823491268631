.container-wide{
  @include breakpoint(xs){
    @include grid(
      '[startLine] auto [endLine]',
      '[startLine] 1fr [endLine]',
      0px,
      'auto',
      '1fr',
      0px
    );
  }
  @include breakpoint(sm){
    @include grid(
      '[startLine] auto [endLine]',
      '[leftSectionLine] 30px [contentLine] 1fr [rightSectionLine] 30px [endLine]',
      0px,
      'auto',
      '30px 1fr 30px',
      0px
    );
  }
  @include breakpoint(md){
    @include grid(
      '[startLine] auto [endLine]',
      '[leftSectionLine] 1fr [contentLine] 960px [rightSectionLine] 1fr [endLine]',
      0px,
      'auto',
      '1fr 960px 1fr',
      0px
    );
  }
  @include breakpoint(lg){
    @include grid(
      '[startLine] auto [endLine]',
      '[leftSectionLine] 1fr [contentLine] 1170px [rightSectionLine] 1fr [endLine]',
      0px,
      'auto',
      '1fr 1170px 1fr',
      0px
    );
  }

  width: 100%;
  background-size: cover;

  .container{
    @include grid-placement(startLine, endLine, startLine, endLine, 1, 1, 1, 1 );

    padding: 60px 20px;

    @include breakpoint(sm){
     @include grid-placement(startLine, endLine, contentLine, rightSectionLine, 1, 1, 2, 1 );
     padding: 100px 30px;
    }

    @include grid(
      '[startLine] auto [endLine]',
      '[startLine] 1fr [endLine]',
      30px,
      'auto',
      '1fr',
      30px
    );

    @include breakpoint(sm){
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 1fr [leftSectionLine] 1fr [rightSectionLine] 1fr [endLine]',
        30px,
        'auto',
        '1fr 30px 1fr 30px 1fr',
        30px
      );
    }

    &.col-1-1{
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 10px [leftSectionLine] 1fr [rightSectionLine] 10px [endLine]',
        30px,
        'auto',
        '10px 30px 1fr 30px 10px',
        30px
      );

      @include breakpoint(sm){
        @include grid(
          '[startLine] auto [endLine]',
          '[startLine] 1fr [leftSectionLine] 1fr [endLine]',
          30px,
          'auto',
          '1fr 30px 1fr',
          30px
        );
      }
    }

    &.col-1-1-1{
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 1fr [endLine]',
        30px,
        'auto',
        '1fr',
        30px
      );

      @include breakpoint(sm){
        @include grid(
          '[startLine] auto [endLine]',
          '[startLine] 1fr [leftSectionLine] 1fr [rightSectionLine] 1fr [endLine]',
          30px,
          'auto',
          '1fr 30px 1fr 30px 1fr',
          30px
        );
      }
    }

    &.col-1-1-1-1{
      @include grid(
        '[startLine] auto [endLine]',
        '[startLine] 1fr [endLine]',
        30px,
        'auto',
        '1fr',
        30px
      );

      @include breakpoint(sm){
        @include grid(
          '[startLine] auto [endLine]',
          '[startLine] 1fr [leftSectionLine] 1fr [middleSectionLine] 1fr [rightSectionLine] 1fr [endLine]',
          30px,
          'auto',
          '1fr 30px 1fr 30px 1fr 30px 1fr',
          30px
        );
      }
    }
  }
}
