html{
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  padding: 0;
  margin: 0;
  min-height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  width: 100%;
  min-height: 100%;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  color: $black-color;
  padding: 0;
  margin: 0;
  background-image: url(../../images/paper-pattern.jpg);
  background-position: left top;
  position: relative;
  display: block;
  overflow-x: hidden;

  &.no-scroll{
    overflow: hidden;
  }

  .global-content{
    background-image: url(../../images/right-spot.png);
    background-position: right 1400px;
    background-size: contain;

    @include breakpoint(sm){
      background-position: right calc(50% - 200px);
      background-size: 834px 1606px;
    }

    @include breakpoint(md){
      background-position: right center;
    }

    background-repeat: no-repeat;
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

p{
  font-size: 1.4rem;
  line-height: 2.0rem;

  @include breakpoint(md){
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &:last-of-type{
    margin-bottom: 0px;
  }
}

.clear{
  clear: both;
}
